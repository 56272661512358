import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, 
IonItem, IonCheckbox , IonLabel, IonBadge, IonButton, IonButtons, IonBackButton, IonCard,IonCardHeader, 
IonCardContent, IonCardTitle, IonCardSubtitle, IonFooter,
 IonNote, IonFab, IonFabButton, IonIcon, IonText} from '@ionic/react';
import { add, alarm, bicycle, home, personCircle, refresh } from 'ionicons/icons';
import axios from 'axios';
import {logout} from '../firebaseConfig'
import './privacy.css';
import {  useDispatch,useSelector } from 'react-redux';




  const Privacy: React.FC<RouteComponentProps> = (props) => {

 function back() {
   window.history.back()
 }
    
 
    return (
      <IonPage>        
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton onClick={back} shape="round" fill="outline" color="light">
              <IonIcon icon={home}/>
                &nbsp;Back
              </IonButton>
           </IonButtons>
           <IonTitle color="light" size="large" class="ion-text-center">
             Privacy Policy
            </IonTitle>                    
          </IonToolbar>
        </IonHeader>
        <IonContent>         
        

        <h1 color="black">Resilience </h1>
<h1>DATA PRIVACY NOTICE</h1>
<b>Your personal data – what is it?</b><br/>
<IonText >
Personal data relates to a living individual who can be identified from that data.
  Identification can be by the information alone or in conjunction with any other
   information in the data controller’s possession or likely to come into such
    possession. The processing of personal data is governed by 
    [the General Data Protection Regulation 2016/679 (the “GDPR”)1]. 
    </IonText><br/><br/>
<b>Who are we?</b><br/>
<IonText color="dark">
Resilience-ppe is the data controller (contact details below).  This means it decides how your personal data is processed and for what purposes.  
</IonText><br/><br/>
<b>How do we process your personal data?</b>  
<IonText color="dark">
Resilience-ppe complies with its obligations under [the GDPR] by 
keeping personal data up to date; by storing and destroying it securely; 
by not collecting or retaining excessive amounts of data; by protecting 
personal data from loss, misuse, unauthorised access and disclosure and by 
ensuring that appropriate technical measures are in place to protect personal data.  
</IonText><br/><br/>

<b>We use your personal data for the following purposes: - </b><br/>
<IonText color="dark">
- For performance reporting - To provide relevant and comparative reporting to yourself and 
you support team on your health, wellbeing and progress.<br/>
- For comparative studies –  To enable us to provide a comparison of data between 
 athletes to enable informed decision making by the relevant Subject Matter 
 Experts on athlete performance<br/>
 - To administer membership records; <br/>  
 - To manage our employees and volunteers<br/>
 - To maintain our own accounts and records.<br/>
 - Our processing also includes the use of CCTV systems for the prevention of crime.<br/>    
 - To operate the Resilience-ppe web site and app and to deliver the services that individuals have 
requested.<br/>
 - To inform individuals of news, events, activities or services running at Resilience-ppe<br/>
 - To contact indivduals via surveys to conduct research about their opinions of current 
services or of potential new services that may be offered.<br/>
</IonText><br/><br/>
<b>What is the legal basis for processing your personal data?</b><br/>
<IonText color="dark">
These fall under either article 6 or article 9 of the GDPR – dealt with separately below.<br/><br/>
<b>Article 6 processing</b><br/>
 - Consent of the data subject<br/>
 - Processing is necessary for the performance of a contract with the data subject 
 or to take steps to enter into a contract<br/>  
 - Processing is necessary for the legitimate interests of the data controller or a 
 third party [e.g. your athletics club, sports team or governing body] to monitor 
 and report on your health, wellbeing and performance, except where such interests 
 are overridden by the interests, rights or freedoms of the data subject.<br/><br/> 
 <b>Article 9 Processing</b><br/>
 - Explicit consent of the data subject; <br/> 
 - Processing relates to personal data manifestly made public by the data subject 
 </IonText><br/><br/>
<b>Where is my data stored and processed? </b><br/>
 <IonText color="dark">
Your data shall be stored and processed on servers located within the 
United Kingdon, European Union and North America. In addition Resilience-ppe employees 
and partners shall store and process your personal data on their own computers.
</IonText><br/><br/>
<b>Sharing your personal data</b><br/>
 <IonText color="dark">
Your personal data will be treated as strictly confidential and will be shared 
only with Resilience-ppe staff, and your relevant support staff [e.g. coach] by 
email, the Resilience website and app and other appropriate means. We will only share your data with third 
parties outside of your support staff with your consent.  
</IonText><br/><br/>
<b>How long do we keep your personal data? </b><br/>
 <IonText color="dark">
We keep your personal data for no longer than reasonably necessary for a period of 
seven year to analysis trends and changes in performance and maturation<br/>  
</IonText><br/><br/>
<b>Your rights and your personal data</b><br/>
 <IonText color="dark">
Unless subject to an exemption [under the GDPR], you have the following rights with 
respect to your personal data: -<br/> 
  - The right to request a copy of the personal data which Resilience-ppe holds about you;<br/>
 - The right to request that Resilience-ppe corrects any personal data if it is found to 
be inaccurate or out of date;<br/>
 - The right to request your personal data is erased where it is no longer necessary for 
Resilience-ppe to retain such data;<br/>  
 - The right to withdraw your consent to the processing at any time.<br/>
 - The right to request that the data controller provide the data subject with his/her 
 personal data and where possible, to transmit that data directly to another data 
controller, (known as the right to data portability).<br/>
 - The right, where there is a dispute in relation to the accuracy or processing of your 
personal data, to request a restriction is placed on further processing;<br/>
 - The right to objection to the processing of personal data<br/>
 - The right to lodge a complaint with the Informaiton Commissioners Office.<br/>
Further processing If we wish to use your personal data for a new purpose, not covered 
by this Data Protection Notice, then we will provide you with a new notice explaining 
this new use prior to commencing the processing and setting out the relevant purposes 
and processing conditions. Where and whenever necessary, we will seek your prior consent 
to the new processing.  
</IonText><br/><br/>
<b>Contact Details</b><br/>
 <IonText color="dark">
To exercise all relevant rights, queries of complaints please in the first instance 
contact the Resilince-ppe at <a href="mailto:info@resilience-ppe.com">info@resilience-ppe.com</a><br/>
&nbsp; &nbsp; Resilience-ppe<br/>
&nbsp; &nbsp; Unit 1 <br/>
&nbsp; &nbsp; 31 Crow Road<br/>
&nbsp; &nbsp; Partick <br/>
&nbsp; &nbsp; Glasgow<br/>
&nbsp; &nbsp; G11 7RT <br/>
  
 </IonText><br/><br/>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle>Footer</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

export default Privacy;

import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import { IonContent, 
	IonHeader, 
	IonPage, 
	IonTitle, IonImg,
	IonToolbar, IonCheckbox,
  IonInput,IonLabel,
  IonLoading,IonItem,
  IonButton, IonText, IonRouterLink, IonList
	} from '@ionic/react';
import { toast } from '../toast';
import {registerUser, getCurrentUser,logout} from '../firebaseConfig';
import wide from './img/r_wide.png';
import axios from 'axios';
import {  useDispatch,useSelector } from 'react-redux';

const Register: React.FC = () => {

  const [busy, setBusy] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [first, setFirst] = useState<string>('')
  const [last, setLast] = useState<string>('')
  const [telephone, setTelephone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [cpassword, setCPassword] = useState<string>('')
  const [accepted, setAccepted] = useState<boolean>(false)

  function alertShow() {    
      const alert = document.createElement('ion-alert');
      alert.header = "Account Created"
      alert.message = 'Please check your email for our verification message. You must click the link in this message before you can login';
      alert.buttons=[
        
        {
          text: 'OK',
          handler: (alertData ) => {
            window.history.replaceState({},'','/login')
            logout()
          }
        }
      ]
      document.body.appendChild(alert);
      return alert.present();
    }
  

  async function  linkResilience(fbUid:string, fbEmail:any,first:any, last:any, telephone:any)  {
    const  apiKEY  =  "hUosmooTQCH4uKwBTVj1";
    const  endpoint  =  `https://www.kystrom.com/api/api.cfm?apiKey=${apiKEY}`;
    var formData = new FormData();
    formData.set('fid', 'link');
    formData.set('uid', fbUid);
    formData.set('email', fbEmail);
    formData.set('first', first);
    formData.set('last', last);
    formData.set('telephone', telephone);
    await axios({
      method: 'post',
      url: endpoint,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          //handle success
          toast('Key registered')
          console.log(response)
      })
      .catch(function (response) {
          //handle error          
          console.log("Key NOT registered ")
          console.log(response)
      });  
    return true
  }


  async function btnRegister() {
    setBusy(true)
    if(password!=cpassword) {
      setBusy(false)
      return toast('Password do not match')
    }   
    if(email.trim() ==='' || password.trim()==='' || cpassword.trim()==='') {
      setBusy(false)
      return toast('Email or password field empty')
    }    
    if(first.trim() ==='' || last.trim()==='') {
      setBusy(false)
      return toast('First or last name field is empty')
    }
    if(password.length >=8 && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/[0-9]/)) {
      //form is completed, register user
      toast('Password strength acceptable')
      const res = await registerUser(email, password)
      getCurrentUser().then((user: any) => {
        if(user) {
          //new account created, user logged in. Send name, email, phone, uid to API then log user out          
          var resEmail=user.sendEmailVerification()
          var resAPI =  linkResilience(user.uid,email,first,last,telephone).then((resAPI: any) => { 
            //login complete, redirect
           alertShow()
           
            //send verification email
            // show alert to say please check email for verification email
            // window.location.reload()         
          })        
        }
      })
    } 
    else {
        toast('Password must be at least 8 characters and contain each of [a-z], [A-Z], [0-9]',10000)
      }
    
  
    setBusy(false)
  }

  
  return (
    <IonPage>
      <IonHeader>
      <IonToolbar color="primary">          
        <IonTitle color="light" size="large" class="ion-text-center">
          Register
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading message="" isOpen={busy}/>
      <IonContent class="ion-text-center">       
        <IonImg id="login-logo" src={wide} />
        <p>
          Already have an account - <Link to="/login">Login</Link>
        </p>
        <IonList inset={true} lines="full">
          <IonItem>
            <IonLabel position="floating">First Name*</IonLabel>
            <IonInput type="text" size={20}
              placeholder="First" 
              onIonChange={(e: any) => setFirst(e.target.value)}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Last Name*</IonLabel>
            <IonInput type="text"
              placeholder="Last" 
              onIonChange={(e: any) => setLast(e.target.value)}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Email*</IonLabel>
            <IonInput type="email"
              placeholder="Email" 
              onIonChange={(e: any) => setEmail(e.target.value)}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Telephone</IonLabel>
            <IonInput type="tel"
              placeholder="Telephone" 
              onIonChange={(e: any) => setTelephone(e.target.value)}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Password*</IonLabel>
            <IonInput type="password"
            placeholder="Password"           
            onIonChange={(e: any) => setPassword(e.target.value)}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Confirm Password*</IonLabel>
            <IonInput  type="password"
              placeholder="Confirm Password"           
              onIonChange={(e: any) => setCPassword(e.target.value)}/>
          </IonItem>
         </IonList>
         
       <p>
       By signing up, you are consenting to our <IonRouterLink color="secondary" href="https://www.kystrom.com/terms.html">Terms of Service</IonRouterLink> and how Kystrom Technologies collects, uses and shares your data 
       as described in our <IonRouterLink color="secondary" href="https://www.kystrom.com/public/Kystrom%20Technologies%20-%20Privacy%20Policy.pdf">Privacy Policy</IonRouterLink> including our use of cookies and similar tracking technology.
       </p>
              
       <IonButton color="secondary" expand="block" onClick={btnRegister}>Register</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Register;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore} from 'redux'
import { Provider, useDispatch,useSelector } from 'react-redux';
import reducer from './store/reducers/reducer';
import * as serviceWorker from './serviceWorker';

const store = createStore(reducer)

ReactDOM.render( 
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
    );
serviceWorker.unregister();
import React, { useState, useEffect, Component } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { add, alarm, bicycle,personCircle, watchOutline, chatbubbleOutline,barbellOutline, home, refresh,arrowBackCircleOutline, checkmark } from 'ionicons/icons';
import { IonContent, 	IonHeader, 	IonPage, 	IonTitle, 	IonToolbar, 
	IonList,   IonItem,   IonInput,  IonIcon,	IonCheckbox , 	IonLabel, 
	IonBadge, 	IonNote,   IonFab,   IonButton,	IonButtons, 	IonBackButton,
  IonDatetime,  IonRange,  IonSelect,  IonSelectOption,IonTextarea, IonItemDivider, IonToggle, IonFabButton, IonFooter	} from '@ionic/react';
  import {logout} from '../firebaseConfig'
import axios from 'axios';
import {  useDispatch,useSelector } from 'react-redux';
import {toast} from '../toast';
import './common.css';

const NewSession: React.FC = () => {
  const uid = useSelector((state:any) => state.user.fb.uid)
  var dtNow = new Date
  var dtUTC = Date.UTC
  var todayDay = dtNow.getDate()
  var todayMonth = dtNow.getMonth()+1
  var todayYear = dtNow.getFullYear()
  var nowHours = dtNow.getHours()
  var nowMinutes = dtNow.getMinutes()


  const [formDate, setFormDate] = useState<string>(todayYear +
    '-'+ todayMonth +'-' + todayDay + ' ' + nowHours +':' + nowMinutes)  

  const [formTempOk1, setTempOk1] = useState(true);
  const [formTempValue, setTempValue] = useState(30.1);
  const [formContact, setContact] = useState(true);
  const [formNormal, setNormal] = useState(false);
  let [formCough, setCough] = useState(true);
  let [formTempOk2, setTempOk2] = useState(true);
  let [formAnosmia, setAnosmia] = useState(true);
  const [formComment, setFormComment] = useState('');
{/*
  const [input, setInput] = useState<string>('')

  useEffect(() => {
    console.log(input)
  },[input])
*/}

 function submitNewCovid1()  {
    const  apiKEY  =  "hUosmooTQCH4uKwBTVj1";
    const  endpoint  =  `https://www.kystrom.com/api/api.cfm?type=covid1&apiKey=${apiKEY}`;
    
    //toast('form submitted 1')
    var formData = new FormData();    
   // var dtDatetime = new Date(formDate)    
    //var dtDatetimeZ = new Date(dtDatetime.getTime() - dtDatetime.getTimezoneOffset()*60000)   
    var dtDatetimeZ = dtNow.toISOString()
    //var strDatetime = dtDatetime.getFullYear() +'-' +dtDatetime.getMonth() +'-'
    //  + dtDatetime.getDate() +'T'+dtDatetime.getHours()+':'+dtDatetime.getMinutes()
    
     // toast('form submitted 2')
    formData.set('fid', 'covid1');
    formData.set('uid', uid);
    console.log(dtDatetimeZ)
    toast(dtDatetimeZ)
    formData.set('date', dtDatetimeZ );
   // toast('form submitted 2.2')
    formData.set('tempOk1', formTempOk1.toString());
    formData.set('tempValue', formTempValue.toString());
    formData.set('contact', formContact.toString());
    formData.set('normal', formNormal.toString());
    formData.set('cough', formCough.toString());
    formData.set('tempOk2', formTempOk2.toString());
    formData.set('anosmia', formAnosmia.toString());
    formData.set('comment', formComment.toString());

   // toast('form submitted 3')

// Add a new document with a generated id.
/*
db.collection("cities").add({
  formData
})
.then(function(docRef) {
  console.log("Document written with ID: ", docRef.id);
})
.catch(function(error) {
  conosle.log(error)
}
*/

/* submit to API... */
    axios({
      method: 'post',
      url: endpoint,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          //handle success
          toast('Form Submitted')          
          window.history.replaceState({},'','/dashboard')          
        window.location.reload()
      })
      .catch(function (response) {
          //handle error      
          toast('Form submit failed...)')    
      });
  
    return true
  }

  const sendGetRequest = (URL: string) => {
    return axios({
      url: URL,
      method: 'get'
    }).then(response => {  
      console.log(response);
      return response.data;
    })
  };



  return (
    <IonPage>
       <IonHeader>
          <IonToolbar color="primary">
          <IonButtons slot="start">          
              <IonButton routerLink="/dashboard"  shape="round" fill="outline" color="light">
              <IonIcon icon={arrowBackCircleOutline}/>
                &nbsp;Back
              </IonButton>
           </IonButtons>
           <IonTitle color="light" class="ion-text-center">COVID Questionnaire</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>                
          </IonToolbar>
        </IonHeader>
      
      <IonContent>
        {/* Date 
        <IonItem>
          <IonLabel position="floating">Date</IonLabel>
          <IonDatetime displayFormat="DD.MM.YYYY HH:mm" value={formDate}
           onIonChange={e => setFormDate(e.detail.value!)} />           
        </IonItem>*/}
        {/* Temp OK 1 
        <IonItem><IonToggle  checked={formTempOk1} onIonChange={e => setTempOk1(e.detail.checked)}/>Do you have a temperature above 37.8 C?</IonItem>
        */}
        {/* Temp Value
        <IonItem>
          <IonLabel position="floating">Your temperature [degrees C]</IonLabel><br/>
          <IonInput  placeholder={formTempValue.toString()} type="number" inputmode="decimal" onIonChange={(e: any) => setTempValue(e.detail.value)}/>
        </IonItem> */}
        {/* Contact */}
        <IonItem><IonToggle  checked={formContact} onIonChange={e => setContact(e.detail.checked)}/>Have you been in contact with anyone with COVID–19 in the last 10 days?</IonItem>
        {/* Feeling normal */}
        <IonItem><IonToggle   checked={formNormal} onIonChange={e => setNormal(e.detail.checked)}class="ion-toggle-reversed"/>Do you feel physically normal?</IonItem>
        <IonItemDivider color="dark">Are you experiencing any of the following...</IonItemDivider>
        {/* Cough */}
        <IonItem><IonToggle  checked={formCough} onIonChange={e => setCough(e.detail.checked)}/>continuous cough?</IonItem>
        {/* Temp OK 2 */}
        <IonItem><IonToggle   checked={formTempOk2} onIonChange={e => setTempOk2(e.detail.checked)}/>fever/high temperature (37.8C or greater)?</IonItem>
        {/* Anosmia */}
        <IonItem><IonToggle   checked={formAnosmia} onIonChange={e => setAnosmia(e.detail.checked)}/>loss of, or change in, sense of smell or taste (anosmia)?</IonItem>
        {/* Comment */}
        <IonItem>
          <IonLabel position="floating">Comment</IonLabel>
          <IonTextarea name="inputComment"
            rows={6} 
            value={formComment}
            onIonChange={(e: any) => setFormComment(e.detail.value)}>
          </IonTextarea>
        </IonItem>
        {/* Submit*/}
        <IonFab vertical="bottom" horizontal="end" slot="fixed">            
            <IonFabButton onClick={submitNewCovid1}  color="success">
              <IonIcon icon={checkmark} />
            </IonFabButton>
          </IonFab>
     {/*
        <IonButton color="secondary" expand="block" onClick={submitNewSession} >Submit</IonButton>
     */}
      </IonContent>
      <IonFooter>
          <IonToolbar>
            <IonTitle>Advert</IonTitle>
          </IonToolbar>
        </IonFooter>
    </IonPage>
  );
};

export default NewSession;

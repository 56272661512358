import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, 
IonItem, IonCheckbox , IonLabel, IonBadge, IonButton, IonButtons, IonBackButton, IonCard,IonCardHeader, 
IonCardContent, IonCardTitle, IonCardSubtitle, IonFooter,
 IonNote, IonFab, IonFabButton, IonIcon, IonText} from '@ionic/react';
import { add, alarm, bicycle, home, personCircle, refresh } from 'ionicons/icons';
import axios from 'axios';
import {logout} from '../firebaseConfig'
import './morning.css';
import {  useDispatch,useSelector } from 'react-redux';





const sendGetRequest = (uid:string) => {
  
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=morningList&apiKey=${uid}`;

  return axios({
    url: API_URL,
    method: 'get'
  }).then(response => {
    console.log(response);
    return response.data;
  })
};



  const Blank: React.FC<RouteComponentProps> = (props) => {
   
    const uid = useSelector((state:any) => state.user.email.uid)
    const [items, setItems] = React.useState([]);  
   
    React.useEffect(() => {
         sendGetRequest(uid).then(data => setItems(data.DATA));
    }, []);

 
    return (
      <IonPage>        
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton routerLink="/"  shape="round" fill="outline" color="light">
              <IonIcon icon={home}/>
                &nbsp;Home
              </IonButton>
           </IonButtons>
           <IonTitle color="light" size="large" class="ion-text-center">
             Training
             </IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>           
          </IonToolbar>
        </IonHeader>
        <IonContent>         
<IonText>Nothing here yet.</IonText>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle>Footer</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

export default Blank;

import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, 
IonItem, IonCheckbox , IonLabel, IonBadge, IonButton, IonButtons, IonBackButton, IonCard,IonCardHeader, 
IonCardContent, IonCardTitle, IonCardSubtitle, IonFooter,
 IonNote, IonFab, IonFabButton, IonIcon, IonText} from '@ionic/react';
import { add, alarm, bicycle, home, personCircle, refresh } from 'ionicons/icons';
import axios from 'axios';
import {logout} from '../firebaseConfig'
import './morning.css';
import {  useDispatch,useSelector } from 'react-redux';





const sendGetRequest = (uid:string) => {  
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=morningList&apiKey=${uid}`;   
  return axios({
    url: API_URL,
    method: 'get'
  }).then(response => {
    console.log(response);
    return response.data;
  })
};

const funItems = (items: never[]) => {
    const hrid: string[] = []; 
    const pcolid: string[] = []; 
    const squid: string[] = []; 
    const sduid: string[] = []; 
    const msoid: string[] = []; 
    const motid: string[] = []; 
    const ftgid: string[] = []; 
    const strid: string[] = []; 
    let intCount = 0;
    let i = 0;
  console.log("funItems: ");
  console.log(items)
  if (items){
    items.forEach(element => {
      if (element[2] < 50) {
        hrid.push("col_10");
      } else if(element[2] < 60) {
        hrid.push("col_8");
      } else if(element[2] < 70) {
        hrid.push("col_6");
      } else if(element[2] < 80) {
        hrid.push("col_4");
      } else if(element[2] < 90) {
        hrid.push("col_2");
      } else{
        hrid.push("col_1");
      }     
      pcolid.push("col_"+(11-element[3]));
      squid.push("col_"+(11-element[4]));
      if (element[5] < 4) {
        sduid.push("col_1");
      } else if(element[5] < 5) {
        sduid.push("col_2");
      } else if(element[5] < 6) {
        sduid.push("col_4");
      } else if(element[5] < 7) {
        sduid.push("col_6");
      } else if(element[5] < 8) {
        sduid.push("col_8");
      } else {
        sduid.push("col_10");
      }
      msoid.push("col_"+element[6]);
      motid.push("col_"+(element[7]));
      ftgid.push("col_"+(11-element[8]));
      strid.push("col_"+(11-element[9]));
    })}

if(items) { return (
  items.map((item,i) => (            
    <IonCard key={item[0]}>                 
      <IonCardHeader>        
      <IonIcon icon={alarm} />&nbsp;  
        {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "short"
        }).format(new Date(item[1]))}       
      </IonCardHeader>
      <IonCardContent>
      <IonBadge id={hrid[i]}>
      HR: { item[2]}
      </IonBadge>
      <IonBadge id={pcolid[i]}>
      PCol: {item[3]}
      </IonBadge>
      <IonBadge id={squid[i]}>
      SQu:{item[4]}
      </IonBadge>
      <IonBadge id={sduid[i]}>
      SDu: {item[5]}
      </IonBadge>
      <IonBadge id={msoid[i]}>
      MSo: {item[6]} 
      </IonBadge>                  
      <IonBadge id={motid[i]}>
      Mot: { item[7]}
      </IonBadge>
      <IonBadge id={ftgid[i]}>
      Ftg: { item[8]} 
      </IonBadge>
      <IonBadge id={strid[i]}>
      Str: { item[9]} 
      </IonBadge><br/>                    
     <IonText>{ item[10]}</IonText>
      </IonCardContent>                 
    </IonCard>
      )))
 }
}

/*const Tab1: React.FC<RouteComponentProps> = (props) => {  DONT USE FIREBASE*/
  const Morning: React.FC<RouteComponentProps> = (props) => {
   
    const uid = useSelector((state:any) => state.user.fb.uid)
    const [items, setItems] = React.useState([]);  
    const hrid: string[] = []; 
    const pcolid: string[] = []; 
    const squid: string[] = []; 
    const sduid: string[] = []; 
    const msoid: string[] = []; 
    const motid: string[] = []; 
    const ftgid: string[] = []; 
    const strid: string[] = []; 
    let intCount = 0;
    let i = 0;
   

    React.useEffect(() => {
     /* sendGetRequest().then(data => setItems(data.articles));*/
      sendGetRequest(uid).then(data => setItems(data.DATA));
    }, []);

 

    /* Colours */
    if (items){
    items.forEach(element => {
      if (element[2] < 50) {
        hrid.push("col_10");
      } else if(element[2] < 60) {
        hrid.push("col_8");
      } else if(element[2] < 70) {
        hrid.push("col_6");
      } else if(element[2] < 80) {
        hrid.push("col_4");
      } else if(element[2] < 90) {
        hrid.push("col_2");
      } else{
        hrid.push("col_1");
      }
     
      pcolid.push("col_"+(11-element[3]));
      squid.push("col_"+(11-element[4]));

      if (element[5] < 4) {
        sduid.push("col_1");
      } else if(element[5] < 5) {
        sduid.push("col_2");
      } else if(element[5] < 6) {
        sduid.push("col_4");
      } else if(element[5] < 7) {
        sduid.push("col_6");
      } else if(element[5] < 8) {
        sduid.push("col_8");
      } else {
        sduid.push("col_10");
      }
      msoid.push("col_"+element[6]);
      motid.push("col_"+(element[10]));
      ftgid.push("col_"+(11-element[11]));
      strid.push("col_"+(11-element[12]));
    })};

   
    return (
      <IonPage>
        
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton routerLink="/"  shape="round" fill="outline" color="light">
              <IonIcon icon={home}/>
                &nbsp;Home
              </IonButton>
           </IonButtons>
           <IonTitle color="light" size="large" class="ion-text-center">Morning</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>           
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {funItems(items)}

          <IonFab vertical="bottom" horizontal="end" slot="fixed">            
            <IonFabButton onClick={() => props.history.push('/newMorning')}  color="secondary">
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>

        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle>Footer</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

export default Morning;

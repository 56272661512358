import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, 
IonItem, IonCheckbox , IonLabel, IonBadge, IonButton, IonButtons, IonBackButton, IonCard,IonCardHeader, 
IonCardContent, IonCardTitle, IonCardSubtitle, IonFooter,
 IonNote, IonFab, IonFabButton, IonIcon, IonText, IonGrid, IonRow, IonCol, IonImg} from '@ionic/react';
import { add, alarm, bicycle, calendar, home, personCircle, refresh,barbell, alarmOutline } from 'ionicons/icons';
import axios from 'axios';
import {logout} from '../firebaseConfig'
import './morning.css';
import {  useDispatch,useSelector } from 'react-redux';





const sendGetRequest = (uid:string) => {
  
  const  API_URL  =  `https://www.kystrom.com/api/garmin.cfm?view&apiKey=${uid}`;
  
 /* NEWS API 
 const API_KEY = '8f14f3cab33c437ba06ef222b509a1e4';
 const API_URL = `https://newsapi.org/v2/top-headlines?sources=techcrunch&apiKey=${API_KEY}`;
 */
  return axios({
    url: API_URL,
    method: 'get'
  }).then(response => {
    console.log(response);
    return response.data;
  })
};

const funItems = (items: any[]) => {
    const hrid: string[] = []; 
    const pcolid: string[] = []; 
    const squid: string[] = []; 
    const sduid: string[] = []; 
    const msoid: string[] = []; 
    const motid: string[] = []; 
    const ftgid: string[] = []; 
    const strid: string[] = []; 
    let intCount = 0;
    let i = 0;
  console.log("funItems: ");
  console.log(items)
  if (items){
    items.forEach(element => {
      if (element[2] < 50) {
        hrid.push("col_10");
      } else if(element[2] < 60) {
        hrid.push("col_8");
      } else if(element[2] < 70) {
        hrid.push("col_6");
      } else if(element[2] < 80) {
        hrid.push("col_4");
      } else if(element[2] < 90) {
        hrid.push("col_2");
      } else{
        hrid.push("col_1");
      }     
      pcolid.push("col_"+(11-element[3]));
      squid.push("col_"+(11-element[4]));
      if (element[5] < 4) {
        sduid.push("col_1");
      } else if(element[5] < 5) {
        sduid.push("col_2");
      } else if(element[5] < 6) {
        sduid.push("col_4");
      } else if(element[5] < 7) {
        sduid.push("col_6");
      } else if(element[5] < 8) {
        sduid.push("col_8");
      } else {
        sduid.push("col_10");
      }
      msoid.push("col_"+element[6]);
      motid.push("col_"+(element[7]));
      ftgid.push("col_"+(11-element[8]));
      strid.push("col_"+(11-element[9]));
    })}

if(items) { return (
  items.map((item,i) => (              
    <IonCard key={item[0]}>                 
      <IonCardHeader>    
           
      <IonIcon icon={alarm} />&nbsp;  
        {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "short"
        }).format(new Date(item[1]))}   
        &nbsp;{ item[2].toFixed(1)}    
      </IonCardHeader>
      <IonCardContent>
      Activity: 
      <IonBadge>
      Act: {item[3].toFixed(1)}
      </IonBadge>
      <IonBadge>
      Dis:{item[4].toFixed(1)}km
      </IonBadge>
      <IonBadge>
      Mod: {item[5].toFixed(1)}
      </IonBadge>
      <IonBadge>
      Vig: {item[6].toFixed(1)} 
      </IonBadge>
      <br/>HR:                  
      <IonBadge>
      Min: { item[7].toFixed(1)}
      </IonBadge>
      <IonBadge>
      Rst: { item[8].toFixed(1)} 
      </IonBadge>  
      <IonBadge>
      Max: { item[9].toFixed(1)} 
      </IonBadge>       
      <br/>Sleep: 
     
      <IonBadge>
      Bed: { item[10]} 
      </IonBadge>  
      <IonBadge>
      Slp: { item[11].toFixed(1)} 
      </IonBadge>  
      </IonCardContent>                 
    </IonCard>
        
      )))
 }
}

/*const Tab1: React.FC<RouteComponentProps> = (props) => {  DONT USE FIREBASE*/
  const Garmin: React.FC<RouteComponentProps> = (props) => {
   
    const uid = useSelector((state:any) => state.user.fb.uid)
    const [items, setItems] = React.useState([]);  
    const hrid: string[] = []; 
    const pcolid: string[] = []; 
    const squid: string[] = []; 
    const sduid: string[] = []; 
    const msoid: string[] = []; 
    const motid: string[] = []; 
    const ftgid: string[] = []; 
    const strid: string[] = []; 
    let intCount = 0;
    let i = 0;
    let dtThis: Date ;
    let strDateCard: string ="Test";

    React.useEffect(() => {
     /* sendGetRequest().then(data => setItems(data.articles));*/
      sendGetRequest(uid).then(data => setItems(data.DATA));
    }, []);

 


   
    return (
      <IonPage>
        
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton routerLink="/"  shape="round" fill="outline" color="light">
              <IonIcon icon={home}/>
                &nbsp;Home
              </IonButton>
           </IonButtons>
           <IonTitle color="light" size="large" class="ion-text-center">Morning</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>           
          </IonToolbar>
        </IonHeader>

        <IonContent>
       
          {
          items.map((item:any,i) => {
            if (item[0] == dtThis) {
              strDateCard ="<IonCard>Date</IonCard>"
              
            } else {
              strDateCard ="<IonCard>Date2</IonCard>"
            }
            
            if (item[12] == 3) {
             return (
         
              <IonCard key={item[0]}>                 
              <IonCardHeader>    
                   
              <IonIcon icon={alarm} />&nbsp;  
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  weekday: "short"
                }).format(new Date(item[1]))}   
                &nbsp;{ item[2].toFixed(1)}    
              </IonCardHeader>
              <IonCardContent>
               
                     
                    <IonBadge>
                    Bed:   {new Intl.DateTimeFormat("en-GB", {
                      hour: "numeric",
                      minute: "numeric"
                    }).format(new Date(item[10]))}  
                    </IonBadge>  
                    <IonBadge>
                    Slp: { item[11].toFixed(1)} 
                    </IonBadge> 
               
              </IonCardContent>                 
            </IonCard>  )
            } else {
              return (
                <IonCard key={item[0]}>                 
                <IonCardHeader>
                <IonIcon icon={calendar} />&nbsp;  
                  {new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    weekday: "short"
                  }).format(new Date(item[1]))}   
               
                </IonCardHeader>
              
                <IonCardContent>
                  
                        
                     
                      
                          Activity: 
                          <IonBadge>
                          Act: {item[3].toFixed(1)}
                          </IonBadge>
                          <IonBadge>
                          Dis:{item[4].toFixed(1)}km
                          </IonBadge>
                          <IonBadge>
                          Mod: {item[5].toFixed(1)}
                          </IonBadge>
                          <IonBadge>
                          Vig: {item[6].toFixed(1)} 
                          </IonBadge>
                          <br/>HR:                  
                          <IonBadge>
                          Min: { item[7].toFixed(1)}
                          </IonBadge>
                          <IonBadge>
                          Rst: { item[8].toFixed(1)} 
                          </IonBadge>  
                          <IonBadge>
                          Max: { item[9].toFixed(1)} 
                          </IonBadge>
                       
                </IonCardContent>                 
            </IonCard> 
                  )
            }
            })}
          {
          /*funItems(items)*/}

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            
            <IonFabButton onClick={() => props.history.push('/newMorning')}  color="primary">
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>

        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle>Footer</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

export default Garmin;

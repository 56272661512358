const defaultState ={
    user: {}
}


export default function reducer(
    state=defaultState, 
    {type,payload}: {type:string;payload:any}
    ):any {
    //work with state here
    switch(type) {
        case 'SET_USER_STATE':
            return {
                ...state,
                user:{fb: payload}
               
            }                     
        case 'SET_USER_INFO':   return {
            ...state,
            user:{fb: payload}          
        }  
                
    }
    return state
}
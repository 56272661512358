import React, { useState, useEffect } from 'react';
import { RouteComponentProps,Link, useHistory, Redirect } from "react-router-dom";
import { IonContent, 
	IonHeader, 
	IonPage, 
	IonToolbar, 
  IonInput,
  IonButton,
  IonLoading,
  IonImg
	} from '@ionic/react';
import {loginUser, getCurrentUser} from '../firebaseConfig';
import {toast} from '../toast';
import { setUserInfo } from '../bin/infoReducer';
import {useDispatch,useSelector } from 'react-redux';
import wide from './img/r_wide.png';
import './dashboard.css';
import axios from 'axios';
import { userInfo } from 'os';

const Login: React.FC = () => {
 
  const [busy, setBusy] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  let history = useHistory()
  const dispatch = useDispatch()

  //TEMP for testing state variable
  const userInfo = useSelector((state:any) => state)
  console.log(userInfo)


  // this function is used to link a firebase account to old resilience account using email address
  async function  linkResilience(fbUid:string, fbEmail:any)  {
    const  apiKEY  =  "hUosmooTQCH4uKwBTVj1";
    const  endpoint  =  `https://www.kystrom.com/api/api.cfm?apiKey=${apiKEY}`;
    var formData = new FormData();
    formData.set('fid', 'link');
    formData.set('uid', fbUid);
    formData.set('email', fbEmail);
    await axios({
      method: 'post',
      url: endpoint,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          //handle success
          toast('Key registered')
          console.log(response)
      })
      .catch(function (response) {
          //handle error          
          console.log("Key NOT registered ")
          console.log(response)
      });  
    return true
  }
   
// this function is used to link a firebase account to old resilience account using email address


// main login function
  async function login() {
    setBusy(true)
    const res = await loginUser(email, password) 
    setBusy(false)
    if(res) {
   //dispatch(setUserInfo("test line 96","123"))
      if (res.user?.emailVerified){
        //Email verified
       // dispatch(setUserInfo(res.user,res.user))
       setBusy(true)
       var resAPI = await linkResilience(res.user.uid,res.user.email)
        setBusy(false)
        if (resAPI) {
            //login complete, redirect
            console.log('Login successful. Redirecting...')            
            window.history.replaceState({},'','/dashboard')           
            window.location.reload()
         
        }        
      } else {
        //Email not verified, send verification email
        toast('Email not verified. Please verify email')
        var resEmail=res.user?.sendEmailVerification()
        const alert = document.createElement('ion-alert');
        alert.header = 'Email Not Verified';
        alert.message = 'Please verify your email address by following the instructions in the email we sent you'
        alert.buttons=[
          {
            text: 'OK', 
            role: 'cancel',
            cssClass: 'secondary'
          }
        ]   
        document.body.appendChild(alert);
        return alert.present();
      }
     
    } else {
      toast('Log in failed. Try again, or register')
    }
    setBusy(false)
  }

  return (
    <IonPage title="Resilience PPE">
      <IonLoading message="" isOpen={busy}/>
      <IonHeader>
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent class="ion-text-center">
        <IonImg id="login-logo" src={wide} />
        <IonInput 
          placeholder="Email" 
          onIonChange={(e: any) => setEmail(e.target.value)} required/>
        <IonInput type="password" placeholder="Password"
          onIonChange={(e: any) => setPassword(e.target.value)} required/>
        <IonButton expand="block" type="submit" color="secondary"onClick={login}>Login</IonButton>
        <p>
          New user? Please <Link to='./register'>Register</Link>
        </p>
      </IonContent>       
    </IonPage>
  );
};

export default Login;

// CL 2020.08.09 need to decide which buttons to show based upon user record
// 70% complete

import React from 'react';
import { RouteComponentProps, useHistory } from "react-router-dom";
import { IonCard, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
IonGrid,IonRow,IonCol, IonButton, IonButtons, IonIcon, IonImg,
IonCardSubtitle, IonLabel,IonText, IonFooter} from '@ionic/react';
import {  useDispatch,useSelector } from 'react-redux';
import {logoutUser,logout} from '../firebaseConfig';
import logo from './img/r_logo.png';
import wide from './img/r_wide.png';
import bicycleOutline from './img/bicycle-outline.svg';
import alarmOutline from './img/alarm-outline.svg';
import linkOutline from './img/link-outline.svg';
import analyticsOutline from './img/analytics-outline.svg';
import checkboxOutline from './img/checkbox-outline.svg';
import callOutline from './img/call-outline.svg';
import businessOutline from './img/business-outline.svg';
import settingsOutline from './img/settings-outline.svg';
import listOutline from './img/list-outline.svg';
import axios from 'axios'

import { add, alarm, bicycle, home, personCircle, refresh, barbellOutline } from 'ionicons/icons';
import './dashboard.css'
import {toast} from '../toast';
import { AnyARecord } from 'dns';

const sendGetRequest = async (uid:string) => {  
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=userInfo&apiKey=${uid}`;   
  const response = await axios({
    url: API_URL,
    method: 'get'
  });
  return response.data;
};

const funButtons = (buttons: any[]) => {
  
  const strButtonName: string[] = [] 
  const strRouterLink: string[] = [] 
  const strIcon: string[] = [] 
  console.log("Buttons: ")
  console.log(buttons)

  if (buttons){
    buttons.forEach(element => { /*     
      if (element[0] === 1) {
        strButtonName.push("Morning")
        strRouterLink.push("/morning")
        strIcon.push(alarmOutline)
      } 
      else if (element[0] === 2) {
        strButtonName.push("Session")
        strRouterLink.push("/session")
        strIcon.push(bicycleOutline)
      }
      else if (element[0] === 3) {
        strButtonName.push("Covid")
        strRouterLink.push("/newCovid1")
        strIcon.push(checkboxOutline)
      }
      else 
      */
      if (element[0] === 4) {
        strButtonName.push("Reception")
        strRouterLink.push("/receptionCovid1")
        strIcon.push(callOutline)
      }
    })
  } 
  console.log(strButtonName)
  return (  buttons.map((item,i) => (     
  <IonCard class="dashboardCard" routerLink={strRouterLink[i]}>
    <IonImg id="dashCardImg" src={strIcon[i]} />
  <IonText>{strButtonName[i]}</IonText>
  </IonCard>

  )))
}

/*
interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
}> {}



const dashboard: React.FC<UserDetailPageProps> = ({match}) => {
  console.log("dashboard");
  */
  /*console.log(match);*/
  const Dashboard: React.FC = () => {

    const fb = useSelector((state:any) => state.user.fb)
    const uid = useSelector((state:any) => state.user.fb.uid)
    const state = useSelector((state:any) => state)
    const userInfo = useSelector((state:any) => state)
    let data:any
    const [buttons, setButtons] = React.useState([]);  
    let showBtn: boolean = false
    let deferredPrompt:any 

    React.useEffect(() => {      
      sendGetRequest(uid).then(data => setButtons(data.DATA))
     }, []);
  
    // sendGetRequest(uid).then(data => setButtons(data.DATA))
   
  

/*

    //install the PWA / add to home screen
    function add_to_home(){
      debugger
      // hide our user interface that shows our button
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            toast('User accepted the prompt');
          } else {
            toast('User dismissed the prompt');
          }
          deferredPrompt = null;
        });
    };

    ionViewWillEnter();

    function ionViewWillEnter(){   
    
      window.addEventListener('beforeinstallprompt', (e) => {
        console.log('before install prompt')
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later on the button event.
        deferredPrompt = e;
         
      // Update UI by showing a button to notify the user they can add to home screen
        showBtn = true;
      });       
      //button click event to show the promt               
      window.addEventListener('appinstalled', (event) => {
        console.log('installed');
      });
       
       
      if (window.matchMedia('(display-mode: standalone)').matches) {
        toast('display-mode is standalone');
      }

    }
   
*/
  function swipeRight(){
    console.log('swipe right')
    toast('swipe right')
  }

  return (
    <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">          
          <IonButton routerLink="/"  shape="round" fill="outline" color="light">
          <IonIcon icon={home}/>
            &nbsp;Home
          </IonButton>
       </IonButtons>
       <IonTitle color="light" size="large" class="ion-text-center"></IonTitle>
        <IonButtons slot="end">                  
          <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
            <IonIcon icon={personCircle}/> 
            &nbsp;Logout           
          </IonButton>                        
        </IonButtons>           
      </IonToolbar>
    </IonHeader>
      <IonContent id="dashboardContent" on-swipe-right="{swipeRight()}">      
        <IonImg src={wide} />
        
        {/* This is a button to install / add to home screen
          
          <IonButton expand="full" onClick={add_to_home}>Install</IonButton>

        */}
      
       
        <IonCard class="dashboardCard"  routerLink="/morning">  
         <IonImg id="dashCardImg" src={alarmOutline}/>          
          <IonText>Morning</IonText>
        </IonCard>  
        <IonCard class="dashboardCard"  routerLink="/session">  
          <IonImg id="dashCardImg" src={bicycleOutline} />        
          <IonText>Session</IonText>
        </IonCard>    
        <IonCard class="dashboardCard"  routerLink="/newCovid1">  
          <IonImg id="dashCardImg" src={checkboxOutline} />        
          <IonText>Covid</IonText>
        </IonCard>  
        <IonCard class="dashboardCard"  routerLink="/groups">  
          <IonImg id="dashCardImg" src={businessOutline} />        
          <IonText>Account & Data Sharing</IonText>
        </IonCard> 
        {funButtons(buttons)}
{/* THESE STILL NEED SOME WORK

            <IonCard class="dashboardCard"  routerLink="/linkedapps">  
              <IonImg id="dashCardImg" src={analyticsOutline} />        
              <IonText>Linked Apps</IonText>
            </IonCard>  

            <IonCard class="dashboardCard"  routerLink="/garmin">  
              <IonImg id="dashCardImg" src={listOutline} />        
              <IonText>Garmin</IonText>
            </IonCard>  
*/}         
    </IonContent>

    <IonFooter>
      <IonToolbar>
        <IonTitle>Version 0.1.005</IonTitle>
      </IonToolbar>
    </IonFooter>
  </IonPage>
  );
};

export default Dashboard;
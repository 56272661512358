import React, { useState, useEffect, Component } from 'react';
import { add, alarm, bicycle,personCircle, watchOutline, chatbubbleOutline,barbellOutline, home, refresh,arrowBackCircleOutline } from 'ionicons/icons';
import { IonContent, 	IonHeader, 	IonPage, 	IonTitle, 	IonToolbar, 	IonList, 
  IonItem,   IonInput,  IonIcon,	IonCheckbox , 	IonLabel, 	IonBadge, 
	IonNote,   IonFab,   IonButton,	IonButtons, 	IonBackButton,  IonDatetime,
  IonRange,  IonSelect,  IonSelectOption,IonTextarea	} from '@ionic/react';
  import {logout} from '../firebaseConfig'
import axios from 'axios';
import {  useDispatch,useSelector } from 'react-redux';
import {toast} from '../toast';

const NewMorning: React.FC = () => {
  const uid = useSelector((state:any) => state.user.fb.uid)
  var todayDay = new Date().getDate()
  var todayMonth = new Date().getMonth()+1
  var todayYear = new Date().getFullYear()
  const [input, setInput] = useState<string>('')
  const [formDate, setFormDate] = useState<string>(todayYear +'-'+ todayMonth +'-' + todayDay);
  const [formHeartRate, setFormHeartRate] = useState(50);
  const [formPeeColour, setFormPeeColour] = useState(0);
  const [formSleepQuality, setFormSleepQuality] = useState(0);
  const [formSleepDuration, setFormSleepDuration] = useState(0);
  const [formMuscleSoreness, setFormMuscleSoreness] = useState(0);
  const [formFatigue, setFormFatigue] = useState(0);
  const [formMotivation, setFormMotivation] = useState(0);
  const [formStress, setFormStress] = useState(0);
  const [formComment, setFormComment] = useState('');

  useEffect(() => {
    console.log(input)
  },[input])


 function submitNewMorning()  {     
    const  apiKEY  =  "hUosmooTQCH4uKwBTVj1";
    const  endpoint  =  `https://www.kystrom.com/api/api.cfm?type=morning&apiKey=${apiKEY}`;
    var formData = new FormData();
    formData.set('fid', 'morning');  
    formData.set('uid', uid.toString());  
    formData.set('record_date', formDate.toString());
    formData.set('hrate', formHeartRate.toString());
    formData.set('pcolour', formPeeColour.toString());
    formData.set('squality', formSleepQuality.toString());
    formData.set('sduration', formSleepDuration.toString());
    formData.set('msoreness', formMuscleSoreness.toString());
    formData.set('fatigue', formFatigue.toString());
    formData.set('motivation', formMotivation.toString());
    formData.set('stress', formStress.toString());
    formData.set('comment', formComment.toString());

 
    axios({
      method: 'post',
      url: endpoint,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          //handle success        
          toast('Morning data add success')
          window.history.replaceState({},'','/morning')          
          window.location.reload()
      })
      .catch(function (response) {
          //handle error  
          toast('Morning data add failed')
      });
  
    return true
  }

  const sendGetRequest = (URL: string) => {
    return axios({
      url: URL,
      method: 'get'
    }).then(response => {  
      console.log(response);
      return response.data;
    })
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton routerLink="/morning"  shape="round" fill="outline" color="light">
              <IonIcon icon={arrowBackCircleOutline}/>
                &nbsp;Back
              </IonButton>
           </IonButtons>
           <IonTitle color="light" size="small" class="ion-text-center">Add morning data</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>            
            </IonButtons>          
          </IonToolbar>
      </IonHeader>
    
      <IonContent>
        {/* Date */}
        <IonItem>
          <IonLabel position="floating">Date</IonLabel>
          <IonDatetime displayFormat="DD.MM.YYYY" value={formDate}
           onIonChange={e => setFormDate(e.detail.value!)} />           
        </IonItem>

        {/* Heart Rate */}        
        <IonItem>
          <IonLabel position="floating">Heart Rate</IonLabel>
            <IonRange pin={true} value={formHeartRate} onIonChange={
              e => setFormHeartRate(e.detail.value as number)}  
              min={20} max={100} color="secondary" />
        </IonItem>

        {/* Pee Colour */}
        <IonItem>
          <IonLabel position="floating">Pee Colour</IonLabel>
          <IonSelect value={formPeeColour} placeholder="Select One" 
          onIonChange={e => setFormPeeColour(e.detail.value)}>
          <IonSelectOption value="1">1 - Clear</IonSelectOption>
          <IonSelectOption value="2">2 - Straw</IonSelectOption>
              <IonSelectOption value="3">3 - Pale Yellow</IonSelectOption>
              <IonSelectOption value="4">4 - Bright Yellow</IonSelectOption>
              <IonSelectOption value="5">5 - Dark Yellow</IonSelectOption>
              <IonSelectOption value="6">6 - Orange</IonSelectOption>
              <IonSelectOption value="7">7 - Pale Brown</IonSelectOption>
              <IonSelectOption value="8">8 - Dark Brown</IonSelectOption>
            </IonSelect>
          </IonItem>

        {/* Sleep Quality */}
        <IonItem>
          <IonLabel position="floating">Sleep Quality</IonLabel>
          <IonSelect value={formSleepQuality} placeholder="Select One" 
          onIonChange={e => setFormSleepQuality(e.detail.value)} color="secondary" >
          <IonSelectOption value="1">1 - Excellent</IonSelectOption>
          <IonSelectOption value="2">2</IonSelectOption>
              <IonSelectOption value="3">3</IonSelectOption>
              <IonSelectOption value="4">4</IonSelectOption>
              <IonSelectOption value="5">5 - Normal</IonSelectOption>
              <IonSelectOption value="6">6</IonSelectOption>
              <IonSelectOption value="7">7</IonSelectOption>
              <IonSelectOption value="8">8</IonSelectOption>
              <IonSelectOption value="9">9</IonSelectOption>
              <IonSelectOption value="10">10 - Insomnia</IonSelectOption>
            </IonSelect>
          </IonItem>

        {/* Sleep Duration */}        
        <IonItem>
          <IonLabel position="floating">Sleep Duration</IonLabel>
            <IonRange pin={true} value={formSleepDuration} onIonChange={
              e => setFormSleepDuration(e.detail.value as number)}  
              min={0} max={12} color="secondary" />
        </IonItem>

        {/* Muscle Soreness */}
        <IonItem>
          <IonLabel position="floating">Muscle Soreness</IonLabel>
          <IonSelect value={formMuscleSoreness} placeholder="Select One"
           onIonChange={e => setFormMuscleSoreness(e.detail.value)}>
           <IonSelectOption value="1">1 - Extreme sore</IonSelectOption>
           <IonSelectOption value="2">2</IonSelectOption>
              <IonSelectOption value="3">3</IonSelectOption>
              <IonSelectOption value="4">4</IonSelectOption>
              <IonSelectOption value="5">5 - Moderate sore</IonSelectOption>
              <IonSelectOption value="6">6</IonSelectOption>
              <IonSelectOption value="7">7</IonSelectOption>
              <IonSelectOption value="8">8</IonSelectOption>
              <IonSelectOption value="9">9</IonSelectOption>
              <IonSelectOption value="10">10 -  Not sore</IonSelectOption>           
            </IonSelect>
          </IonItem>

        {/* Fatigue */}
        <IonItem>
          <IonLabel position="floating">Fatigue</IonLabel>
          <IonSelect value={formFatigue} placeholder="Select One"
           onIonChange={e => setFormFatigue(e.detail.value)}>
           <IonSelectOption value="1">1 - Not fatigued</IonSelectOption>
           <IonSelectOption value="2">2</IonSelectOption>
              <IonSelectOption value="3">3</IonSelectOption>
              <IonSelectOption value="4">4</IonSelectOption>
              <IonSelectOption value="5">5</IonSelectOption>
              <IonSelectOption value="6">6</IonSelectOption>
              <IonSelectOption value="7">7</IonSelectOption>
              <IonSelectOption value="8">8</IonSelectOption>
              <IonSelectOption value="9">9</IonSelectOption>
              <IonSelectOption value="10">10 - Very Fatigued</IonSelectOption>            
            </IonSelect>
          </IonItem>
 {/* Motivation */}
 <IonItem>
          <IonLabel position="floating">Motivation</IonLabel>
            <IonSelect value={formMotivation} placeholder="Select One"
             onIonChange={e => setFormMotivation(e.detail.value)}
             id="motivation">
             <IonSelectOption value="1">1 - Not motivated</IonSelectOption>
             <IonSelectOption value="2">2</IonSelectOption>
              <IonSelectOption value="3">3</IonSelectOption>
              <IonSelectOption value="4">4</IonSelectOption>
              <IonSelectOption value="5">5</IonSelectOption>
              <IonSelectOption value="6">6</IonSelectOption>
              <IonSelectOption value="7">7</IonSelectOption>
              <IonSelectOption value="8">8</IonSelectOption>
              <IonSelectOption value="9">9</IonSelectOption>
              <IonSelectOption value="10">10 - Very motivated</IonSelectOption>
            </IonSelect>
          </IonItem>
          
          
        {/* Stress */}
        <IonItem>
          <IonLabel position="floating">Stress</IonLabel>
            <IonSelect value={formStress} placeholder="Select One"
             onIonChange={e => setFormStress(e.detail.value)}>
             <IonSelectOption value="1">1 - Not stressed</IonSelectOption>
             <IonSelectOption value="2">2</IonSelectOption>
              <IonSelectOption value="3">3</IonSelectOption>
              <IonSelectOption value="4">4</IonSelectOption>
              <IonSelectOption value="5">5</IonSelectOption>
              <IonSelectOption value="6">6</IonSelectOption>
              <IonSelectOption value="7">7</IonSelectOption>
              <IonSelectOption value="8">8</IonSelectOption>
              <IonSelectOption value="9">9</IonSelectOption>
              <IonSelectOption value="10">10 - Very stressed</IonSelectOption>
            </IonSelect>
          </IonItem>
          
        {/* Comment */}
        <IonItem>
          <IonLabel position="floating">Comment</IonLabel>
          <IonTextarea
            rows={6} 
            value={formComment}
            onIonChange={(e: any) => setFormComment(e.target.value)}>
          </IonTextarea>
        </IonItem>

        {/* Submit*/}
        <IonButton color="secondary" expand="block" onClick={submitNewMorning} >Submit</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default NewMorning;

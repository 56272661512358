import React, { useState, useEffect, Component } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { add, alarm, bicycle,personCircle, watchOutline, chatbubbleOutline,barbellOutline, home, refresh,arrowBackCircleOutline } from 'ionicons/icons';
import { IonContent, 	IonHeader, 	IonPage, 	IonTitle, 	IonToolbar, 
	IonList,   IonItem,   IonInput,  IonIcon,	IonCheckbox , 	IonLabel, 
	IonBadge, 	IonNote,   IonFab,   IonButton,	IonButtons, 	IonBackButton,
  IonDatetime,  IonRange,  IonSelect,  IonSelectOption,IonTextarea	} from '@ionic/react';
  import {logout} from '../firebaseConfig'
import axios from 'axios';
import {  useDispatch,useSelector } from 'react-redux';
import {toast} from '../toast';

const NewSession: React.FC = () => {
  const uid = useSelector((state:any) => state.user.fb.uid)
  var dtNow = new Date
  var todayDay = dtNow.getDate()
  var todayMonth = dtNow.getMonth()+1
  var todayYear = dtNow.getFullYear()
  var nowHours = dtNow.getHours()
  var nowMinutes = dtNow.getMinutes()
  const [input, setInput] = useState<string>('')
  const [formDate, setFormDate] = useState<string>(todayYear +
    '-'+ todayMonth +'-' + todayDay + ' ' + nowHours +':' + nowMinutes)
  const [formUID, setFormUID] = useState(0);
  const [formType, setFormType] = useState(0);
  const [formDuration, setFormDuration] = useState(0);
  const [formRPE, setFormRPE] = useState(0);
  const [formComment, setFormComment] = useState('');

  useEffect(() => {
    console.log(input)
  },[input])


 function submitNewSession()  {
    const  apiKEY  =  "hUosmooTQCH4uKwBTVj1";
    const  endpoint  =  `https://www.kystrom.com/api/api.cfm?type=session&apiKey=${apiKEY}`;
    var formData = new FormData();    
    var dtDatetime = new Date(formDate)    
    var dtDatetimeZ = new Date(dtDatetime.getTime() - dtDatetime.getTimezoneOffset()*60000)
    var strDatetime = dtDatetime.getFullYear() +'-' +dtDatetime.getMonth() +'-'
    + dtDatetime.getDate() +'T'+dtDatetime.getHours()+':'+dtDatetime.getMinutes()
    formData.set('fid', 'session');
    formData.set('uid', uid);
    formData.set('date', dtDatetimeZ.toISOString() );
    formData.set('uid', uid);
    formData.set('type', formType.toString());
    formData.set('duration', formDuration.toString());
    formData.set('rpe', formRPE.toString());
    formData.set('comment', formComment.toString());

    console.log('UID:' + uid)

    axios({
      method: 'post',
      url: endpoint,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          //handle success
          toast('Session added')          
          window.history.replaceState({},'','/session')          
          window.location.reload()
      })
      .catch(function (response) {
          //handle error      
          toast('Session add failed...)')    
      });
  
    return true
  }

  const sendGetRequest = (URL: string) => {
    return axios({
      url: URL,
      method: 'get'
    }).then(response => {  
      console.log(response);
      return response.data;
    })
  };

  return (
    <IonPage>
       <IonHeader>
          <IonToolbar color="primary">
          <IonButtons slot="start">          
              <IonButton routerLink="/session"  shape="round" fill="outline" color="light">
              <IonIcon icon={arrowBackCircleOutline}/>
                &nbsp;Back
              </IonButton>
           </IonButtons>
           <IonTitle color="light" class="ion-text-center">Add session data</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>                
          </IonToolbar>
        </IonHeader>
      
    
      <IonContent>
        {/* Date */}
        <IonItem>
          <IonLabel position="floating">Date</IonLabel>
          <IonDatetime displayFormat="DD.MM.YYYY HH:mm" value={formDate}
           onIonChange={e => setFormDate(e.detail.value!)} />           
        </IonItem>

       

        {/* Type */}
        <IonItem>
          <IonLabel position="floating">Sesion Type</IonLabel>
          <IonSelect value={formType} placeholder="Select One" 
          onIonChange={e => setFormType(e.detail.value)}>         
              <IonSelectOption value="1">Sport Specific</IonSelectOption>
              <IonSelectOption value="2">Strength</IonSelectOption>
              <IonSelectOption value="3">Conditioning CV</IonSelectOption>
              <IonSelectOption value="4">Recovery</IonSelectOption>
              <IonSelectOption value="5">Competition</IonSelectOption>
              <IonSelectOption value="6">Testing</IonSelectOption>
              <IonSelectOption value="7">Physiotherapy</IonSelectOption>
            </IonSelect>
          </IonItem>

        {/* Duration */}        
        <IonItem>
        <IonLabel position="floating">Duration (minutes)</IonLabel>
          <IonRange pin={true} value={formDuration} onIonChange={
            e => setFormDuration(e.detail.value as number)}  
            min={0} max={200} color="secondary" />
        </IonItem>

        {/* Sleep Quality */}
        <IonItem>
          <IonLabel position="floating">RPE</IonLabel>
          <IonSelect value={formRPE} placeholder="Select One" 
          onIonChange={e => setFormRPE(e.detail.value)} color="secondary" >
          <IonSelectOption value="0">0 - Nothing</IonSelectOption>
          <IonSelectOption value="1">1 - Very light</IonSelectOption>
          <IonSelectOption value="2">2 - Light</IonSelectOption>
              <IonSelectOption value="3">3 - Moderate</IonSelectOption>
              <IonSelectOption value="4">4 - Somewhat hard</IonSelectOption>
              <IonSelectOption value="5">5 - Hard</IonSelectOption>
              <IonSelectOption value="6">6 - </IonSelectOption>
              <IonSelectOption value="7">7 - Very hard</IonSelectOption>
              <IonSelectOption value="8">8 - </IonSelectOption>
              <IonSelectOption value="9">9 - </IonSelectOption>
              <IonSelectOption value="10">10 - Maximum</IonSelectOption>
            </IonSelect>
          </IonItem>
          
        {/* Comment */}
        <IonItem>
          <IonLabel position="floating">Comment</IonLabel>
          <IonTextarea
            rows={6} 
            value={formComment}
            onIonChange={(e: any) => setFormComment(e.target.value)}>
          </IonTextarea>
        </IonItem>

        {/* Submit*/}
        <IonButton color="secondary" expand="block" onClick={submitNewSession} >Submit</IonButton>

      </IonContent>
    </IonPage>
  );
};

export default NewSession;

import React from 'react';
import { RouteComponentProps, useHistory } from "react-router-dom";
import { IonCard, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
IonGrid,IonRow,IonCol, IonButton, IonButtons, IonIcon,
IonText, IonImg, IonLabel, IonCardSubtitle, IonCardContent} from '@ionic/react';
import {  useDispatch,useSelector } from 'react-redux';
import {logoutUser,logout, getCurrentUser, writeUserData} from '../firebaseConfig';
import logo from './img/r_logo.png';
import wide from './img/r_wide.png';
import bicycleOutline from './img/bicycle-outline.svg';
import alarmOutline from './img/alarm-outline.svg';
import linkOutline from './img/link-outline.svg';
import analyticsOutline from './img/analytics-outline.svg';
import listOutline from './img/list-outline.svg';

import { add, alarm, bicycle, home, personCircle, refresh, barbellOutline } from 'ionicons/icons';

import {toast} from '../toast';
import axios from 'axios';


  const Linkedapps: React.FC = () => {
    const user = useSelector((state:any) => state.user)
    const email = useSelector((state:any) => state.user.email.email)
    const uid = useSelector((state:any) => state.user.fb.uid)
    console.log("dashboard")
    console.log()

    function swipeRight(){
      console.log('swipe right')
      toast('swipe right')
    }
 
   

    function linkResilience()  {
      const  apiKEY  =  "hUosmooTQCH4uKwBTVj1";
      const  endpoint  =  `https://www.kystrom.com/api/api.cfm?apiKey=${apiKEY}`;
      var formData = new FormData();
      formData.set('fid', 'link');
      formData.set('uid', uid);
      formData.set('email', email);
      axios({
        method: 'post',
        url: endpoint,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            console.log("Success: ")
            console.log(response)
            console.log(user.email.uid)
            writeUserData(response.data, 
              user.email.uid)
        })
        .catch(function (response) {
            //handle error          
            console.log("Link Failure: ")
            console.log(response)
        });
    
      return true
    }
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">          
          <IonButton routerLink="/"  shape="round" fill="outline" color="light">
          <IonIcon icon={home}/>
            &nbsp;Home
          </IonButton>
       </IonButtons>
       <IonTitle color="light" class="ion-text-center">
         Linked Apps
         </IonTitle>
        <IonButtons slot="end">                  
          <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
            <IonIcon icon={personCircle}/> 
            &nbsp;Logout           
          </IonButton>                        
        </IonButtons>           
      </IonToolbar>
    </IonHeader>
    <IonContent  on-swipe-right={swipeRight()}>          
      <IonCard>             
        <IonCardTitle>Resilience v1</IonCardTitle>
        <IonCardSubtitle>Not connected</IonCardSubtitle>
        <IonCardContent>
          <IonButton  onClick={linkResilience}>Link</IonButton>
        </IonCardContent>
      </IonCard>
      <IonCard routerLink="/session">                
        <IonCardTitle>Garmin</IonCardTitle>
          <IonText>Not connected</IonText>
      </IonCard> 
      <IonCard routerLink="/linkedapps">  
        <IonCardTitle>Fitbit</IonCardTitle>              
        <IonText>Not connected</IonText>
      </IonCard>  
    </IonContent>
  </IonPage>
  );
};

export default Linkedapps;
//CL 2020-08-07 Copied made from reception
// Purpose of this file is to
// list groups, and when clicked allow the user to decide which data to share (on unshare) with the group owners. Include a link to the organisation's privacy policy where relvant
// also add new groups by entering
// So far 0% complete

import React, { useState, useEffect, createElement } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, 
IonItem, IonCheckbox , IonLabel, IonBadge, IonButton, IonButtons, IonBackButton, IonCard,IonCardHeader, 
IonCardContent, IonCardTitle, IonCardSubtitle, IonFooter,
 IonNote, IonFab, IonFabButton, IonIcon, IonText, IonGrid, IonRow, IonCol, IonImg, IonLoading, IonAlert, IonItemSliding, IonItemOptions, IonItemOption, IonSearchbar, IonRouterLink} from '@ionic/react';
import { add, alarm, bicycle, calendar, home, personCircle, refresh,barbell, alarmOutline, checkboxOutline } from 'ionicons/icons';
import axios from 'axios';
import {logout} from '../firebaseConfig'
import './morning.css';
import {  useDispatch,useSelector } from 'react-redux';
import { toast } from '../toast';
import { create } from 'domain';
import {userDelete} from '../firebaseConfig';

const Organisations: React.FC<RouteComponentProps> = (props) => {
    const [busy, setBusy] = useState<boolean>(false)
    const [showAlert1, setShowAlert1] = useState(false);
    const uid = useSelector((state:any) => state.user.fb.uid)
    const [items, setItems] = React.useState(['']);   
    const [filteredItems, setFilteredItems] = React.useState(['']);      
    const [userGroups, setUserGroups] = React.useState(['']);     
    const [searchText, setSearchText] = useState('');  
   

    //search filter
    useEffect(() => {
      // this function filters the 'items' list based upon 'searchText' finally setting filteredItems with the result
      if (searchText !== "") {
      //  let newItems:any = items.filter(item => 
      //  item[0].toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || 
      //  item[1].toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
       // ) 
     //   setFilteredItems(newItems)
        sendGetRequest(uid).then(data => {
      //  setItems(data.DATA)
        //setFilteredItems(data.DATA)
        console.log(data.DATA)
        console.log("searchText:"+ searchText)
        groupSearch().then(data => {
          setItems(data.DATA)
          setFilteredItems(data.DATA)
          console.log("search text not null!")}
          )        
        // setFilteredItems(newItems);
        })
      }
       else {
    //    setFilteredItems(items)   
      getUserGroups()     
      }
    }, [searchText])

    const questValue: string[] = []; 
    const tempValue: string[] = []; 
    const approvedCol: string[] = []; 
    const approvedValue: string[] = []; 

    async function getUserGroups() {
      sendGetRequest(uid).then(data2 => {
        //setItems(data.DATA)
        setUserGroups(data2.DATA)
      })
    }

//when the form is dismissed, send the new group id and uid to the API
function groupJoin (id: number) {   
  //setBusy(true)
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=groupJoin&apiKey=${uid}&groupId=${id.toString()}`;
  return axios({url: API_URL, method: 'get'}).then(response => {
   // setBusy(false)
    getUserGroups()
    toast("Joine Group")
    return;
  }
  )      
}

function groupLeave (id: number) {   
 // setBusy(true)
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=groupLeave&apiKey=${uid}&groupId=${id.toString()}`;
  return axios({url: API_URL, method: 'get'}).then(response => {
    //setBusy(false)
    getUserGroups()
    toast("Left Group")
    return;
  }
  )      
}


async function accountDelete () {   
  setBusy(true)
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=accountDelete&apiKey=${uid}`;
  axios({url: API_URL, method: 'get'}).then(async response => {
   await userDelete().then(async response => {
    console.log(response)
    window.history.replaceState({},'','/login')
    window.location.reload()
   })
   /*
   if (fbUserDelete) {
     
    console.log("defined")
    console.log(fbUserDelete)
    window.history.replaceState({},'','/login')
    //window.location.reload()
   } else {
    console.log("undef")
      console.log(fbUserDelete)
   }
    */
    setBusy(false)
    return true;
  }
  )      
}


//this function sends new organisation details to the API
function functionAddOrganisationDismiss (code: number ) {   
  //setBusy(true)
  var formData = new FormData();  
  var approved = 1;
  console.log(code)
  formData.set('fid', 'addOrganisatio');
  formData.set('uid', uid);
  formData.set('code', code.toString());
  formData.set('approved', approved.toString());
  
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=addOrganisation&apiKey=${uid}`;
  return axios({
    url: API_URL,
    method: 'post',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data' }
  }).then(response => {
    console.log(response);
   // setBusy(false)
    getUserGroups();
    return response.data;  
    }
    )      
    
} 

//when a card is clicked, this function is called. It creates a popup form
function functionAddOrganisation() {
  console.log("card clicked")
  toast("card clicked: ")
    const alert = document.createElement('ion-alert');
    //alert.header = first + ' ' + last;
    alert.message = 'Enter Organisation Code';
    alert.buttons=[
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          toast('Confirm Cancel');
        }
      },
      {
        text: 'Submit',
        handler: (alertData ) => {
          toast('Confirm Ok');
          functionAddOrganisationDismiss(alertData.code)
        }
      }
    ]
    alert.inputs=[
      {
        name: 'code',
        type: 'text',
        min: 32.5,
        max: 45,
        placeholder: 'Code'                
      }]
    document.body.appendChild(alert);
    return alert.present();
  }

//Open form
function cardClick(id: number,name:string) {
  console.log("card clicked")
  toast("card clicked: " + id.toString())
    const alert = document.createElement('ion-alert');
    alert.header = name;
    alert.message = 'Description';
    alert.buttons=[
      {
        text: 'Leave',
        cssClass: 'secondary',
        handler: () => {
          toast('Confirm Cancel')
          groupLeave(id)
        }
      },
      {
        text: 'Join',
        handler: () => {
          toast('Join')
          groupJoin(id)
        }
      }
    ]   
    document.body.appendChild(alert);
    return alert.present();
  }
  //Confirm Delete
   function accountDeleteConfirm() {    
      const alert = document.createElement('ion-alert');
      alert.header = 'Confirm Account Deletion';
      alert.message = 'Click DELETE to confirm deletion of your account'
      alert.buttons=[
        {
          text: 'Cancel', 
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            toast('Deletion Cancelled')            
          }
        },
        {
          text: 'Delete',
          handler: () => {
            accountDelete()
          }
        }
      ]   
      document.body.appendChild(alert);
      return alert.present();
    }


// this function calls the API top search for new squads
async function groupSearch(){
  //setBusy(true)
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=groupSearch&apiKey=${uid}&searchText=${searchText}`;
  return axios({
    url: API_URL,
    method: 'get'
  }).then(response => {
    //console.log(response);
    setBusy(false)
    return response.data;
  }
  )      
};

// this function gets the list of groups the uid is a member of
    async function sendGetRequest(uid:string){
      //setBusy(true)
      const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=groupList&apiKey=${uid}`;
      return axios({
        url: API_URL,
        method: 'get'
      }).then(response => {
        //console.log(response);
        setBusy(false)
        return response.data;
      }
      )      
    };



    //get list from API
    /* 2020-10-04, not needed, now show two seprate lists
    React.useEffect(() => {
     // sendGetRequest().then(data => setItems(data.articles));
      sendGetRequest(uid).then(data => {setItems(data.DATA)
      if (data.DATA) {
        setFilteredItems(data.DATA)}
      });
    }, []);
   */
    return (
      <IonPage>        
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton routerLink="/"  shape="round" fill="outline" color="light">
              <IonIcon icon={home}/>
                &nbsp;Home
              </IonButton>
           </IonButtons>
           <IonTitle color="light" class="ion-text-center">Groups</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>           
          </IonToolbar>
        </IonHeader>
        <IonLoading message="" isOpen={busy}/>
        <IonContent>
       
       
          <h3>Privacy</h3>
          Please refer to our <IonRouterLink color="secondary" href="https://www.kystrom.com/public/Kystrom%20Technologies%20-%20Privacy%20Policy.pdf">Privacy Policy</IonRouterLink> for infromation about how we use your personal data.<br/>
       
          <h3>Delete Account</h3>
          To completely delete your account and all your data held in our system, press <b onClick={() => accountDeleteConfirm()}  color="secondary">here</b> and select 'Delete'
          
          <h3>Data Sharing</h3>
            You can give your consent for us to share your data with certain groups, for exampe your gym, sports club or team.<br/>          
          <br/>
          The groups you are already sharing your data with are shown below. To remove a group select it, and press 'Leave'.<br/>
          <IonList>        
        { userGroups.map((item,i) => (        
          <IonItemSliding>
          <IonItem key={item[2]} button onClick={() => {cardClick(parseInt(item[0]),item[1])}}>
          <IonLabel class="ion-text-wrap" id={item[0]}>
              <h1>
                {item[1]} 
              </h1>
              </IonLabel>
            </IonItem>
            <IonItemOptions side="end">
              <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
            </IonItemOptions>
          </IonItemSliding> 
          ))}
        </IonList>
          <br/>
          To share your data with a new group enter the <b>code</b> you were given in the box below, press on the group name, and select 'Join'<br/>
          <br/>
          <IonSearchbar debounce={300} placeholder="Enter Group Code" value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
        
        <IonList>        
        { filteredItems.map((item,i) => (        
          <IonItemSliding>
          <IonItem key={item[2]} button onClick={() => {cardClick(parseInt(item[0]),item[1])}}>
          <IonLabel class="ion-text-wrap" id={item[0]}>
              <h1>
                {item[1]} 
              </h1>
              </IonLabel>
            </IonItem>
            <IonItemOptions side="end">
              <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
            </IonItemOptions>
          </IonItemSliding> 
          ))}
        </IonList>
      {/*}
        <IonFab vertical="bottom" horizontal="end" slot="fixed">            
          //<IonFabButton onClick={() => sendGetRequest(uid).then(data => setItems(data.DATA))}  color="secondary"> 
          <IonFabButton onClick={() => functionAddOrganisation()}  color="secondary">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
        */}

        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle>Advert</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

export default Organisations;

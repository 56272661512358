import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, 
IonItem, IonCheckbox , IonLabel, IonBadge, IonButton, IonButtons, IonBackButton, IonCard,IonCardHeader, 
IonCardContent, IonCardTitle, IonCardSubtitle, IonFooter,
 IonNote, IonFab, IonFabButton, IonIcon, IonText} from '@ionic/react';
import { add, alarm, bicycle,personCircle, watchOutline, chatbubbleOutline,barbellOutline, home, refresh } from 'ionicons/icons';
import axios from 'axios';
import {logout} from '../firebaseConfig'
import './session.css';
import { logoutUser } from '../firebaseConfig';
import {  useDispatch,useSelector } from 'react-redux';

const sendGetRequest = (uid:string) => {
  
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=sessionList&apiKey=${uid}`;
  
  return axios({
    url: API_URL,
    method: 'get'
  }).then(response => {
    console.log(response);
    return response.data;
  })
};

/*const Tab1: React.FC<RouteComponentProps> = (props) => {  DONT USE FIREBASE*/
  const Session: React.FC<RouteComponentProps> = (props) => {
    
    const uid = useSelector((state:any) => state.user.fb.uid)
    const [items, setItems] = React.useState([]);  
    const hrid: string[] = []; 
    const type: string[] = []; 
    const rpeid: string[] = []; 
    const sduid: string[] = []; 
    const msoid: string[] = []; 
    const motid: string[] = []; 
    const ftgid: string[] = []; 
    const strid: string[] = []; 
    let intCount = 0;
    let i = 0;
   

    React.useEffect(() => {
     /* sendGetRequest().then(data => setItems(data.articles));*/
      sendGetRequest(uid).then(data => setItems(data.DATA));
    }, []);

  

    /* Colours */
    items.forEach(element => {
      rpeid.push("col_"+(11-element[3]));
    });
  
   
    return (
      <IonPage>
        
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton routerLink="/"  shape="round" fill="outline" color="light">
              <IonIcon icon={home}/>
                &nbsp;Home
              </IonButton>
           </IonButtons>
           <IonTitle color="light" size="large" class="ion-text-center">Session</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>           
          </IonToolbar>
        </IonHeader>

        <IonContent>        
            {items.map((item,i) => (              
                <IonCard key={item[0]}>                 
                  <IonCardHeader>
                    <IonCardTitle>                    
                        <IonIcon icon={bicycle} />&nbsp;
                        
                        <IonText color="medium">
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          weekday: "short",
                          hour: 'numeric', minute: 'numeric'
                        }).format(new Date(item[1]))}
                        </IonText>                       
                        <IonText color="medium"> / </IonText>                       
                        <IonText color="medium"> {item[2]} mins</IonText>
                        <IonText color="medium"> / </IonText>
                        <IonText color="medium">{ item[5]}</IonText>                        
                        <IonText color="medium"> / </IonText>
                        <IonText id={rpeid[i]}>RPE:{item[3]}</IonText>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>                                 
                    {item[4]}
                  </IonCardContent>                 
                </IonCard>
              ))}
      
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => props.history.push('/newSession')}  color="secondary">
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </IonContent>
        <IonFooter color="primary">
          <IonToolbar>
            <IonTitle>Footer</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

export default Session;

import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonSpinner,
  IonRouterOutlet,
  isPlatform
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {  useDispatch,useSelector } from 'react-redux';

/* AdMob */
 import { Plugins } from '@capacitor/core';
 import { AdOptions, AdSize, AdPosition } from '@rdlabo/capacitor-admob';



/* Pages */
import privacy from './pages/privacy';
import login from './pages/login';
import register from './pages/register';
import morning from './pages/morning';
import session from './pages/session';
import newMorning from './pages/newMorning';
import newSession from './pages/newSession';
import newCovid1 from './pages/newCovid1';
import groups from './pages/groups';
import receptionCovid1 from './pages/receptionCovid1';
import dashboard from './pages/dashboard';
import linkedapps from './pages/linkedapps';
import training from './pages/training';
import garmin from './pages/garmin';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {getCurrentUser} from './firebaseConfig';
import { setUserState } from './bin/authReducer';
import { setUserInfo } from './bin/infoReducer';

const RoutingSystem: React.FC =() => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/privacy" component={privacy} />
        <Route path="/login" component={login} />
        <Route path="/register" component={register} />
        <Route path="/dashboard/" component={dashboard} />
        <Route path="/session" component={session} exact={true} />
        <Route path="/morning" component={morning} exact={true} />
        <Route path="/newMorning" component={newMorning} />
        <Route path="/newSession" component={newSession} />
        <Route path="/newCovid1" component={newCovid1} />
        <Route path="/groups" component={groups} />
        <Route path="/receptionCovid1" component={receptionCovid1} />
        <Route path="/linkedapps" component={linkedapps} />
        <Route path="/training" component={training} />
        <Route path="/garmin" component={garmin} />
        <Route path="/" render={() => <Redirect to="/dashboard" />} exact={true} />
        </IonRouterOutlet>
    </IonReactRouter>
  )
}

const App: React.FC = () => {
  const [busy, setBusy] = useState(true)
  const dispatch = useDispatch()


  
/* AdMob 
const { AdMob } = Plugins;
AdMob.initialize();

   const adId = {
     ios: 'ca-app-pub-3940256099942544/6300978111',
     android: 'ca-app-pub-3940256099942544/6300978111'
     // ca-app-pub-4601691629871088~4495819334
   }
   
   const platformAdId = isPlatform('android') ? adId.android : adId.ios;
 
   const options: AdOptions = {
     adId: platformAdId,
     adSize: AdSize.BANNER,
     position: AdPosition.BOTTOM_CENTER,     
      isTesting: true
   }
 
   AdMob.showBanner(options);
 
   // Subscibe Banner Event Listener
   AdMob.addListener('onAdLoaded', (info: boolean) => {
     console.log("Banner ad loaded");
   });
 
   // Get Banner Size
   AdMob.addListener('onAdSize', (info: boolean) => {
     console.log(info);
   });
   */
  useEffect(() => {
    getCurrentUser().then((user: any) => {
      if(user) {
        //logged in
        console.log(' this is what user is');
        console.log(user);
        dispatch(setUserState(user));
       

        //window.history.replaceState({},'','/dashboard')
      } 
      else if (window.location.href.includes('/register')){
        //registration page, not need to be logged in yet
        console.log('contains reg')
      }
      else if (window.location.href.includes('/privacy')){
        //privacy policy, not need to be logged in yet
      } 
      else {
        //redirect to login page
        window.history.replaceState({},'','/login')
      }
      setBusy(false)
    })
  }, [])

  return (
    <IonApp> {
      busy ? <IonSpinner /> : <RoutingSystem />
    }          
    </IonApp>
  )
};

export default App;

import * as firebase  from "firebase";
import { toast } from "./toast";
import { resolve } from "dns";
import { RouteComponentProps,Link, useHistory } from "react-router-dom";
import { isDate } from "util";

const config = {
    apiKey: "AIzaSyCehtPe8MAnNUgw-rNobpwogpkA-CIi6cM",
    authDomain: "resilience-ppe.firebaseapp.com",
    databaseURL: "https://resilience-ppe.firebaseio.com",
    projectId: "resilience-ppe",
    storageBucket: "resilience-ppe.appspot.com",
    messagingSenderId: "674157230210",
    appId: "1:674157230210:web:66e3293add713e81545c9b",
    measurementId: "G-DKFF036TGC"
}



firebase.initializeApp(config)

const db = firebase.firestore();

export function getCurrentUser() {
   return new Promise ((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
       console.log(user);
        if(user) {
            resolve(user)           
        } else {
            resolve(null)
        }
        unsubscribe()
    })
   })   
}

export function writeUserData(apikey:string, uid:string) {
    db.collection('users').add({
        uuid: uid,
        reskey: apikey,
        date: Date.now()
    });
  }

export async function logout() {    
    logoutUser()    
    window.location.reload()
  }

export function logoutUser() {
    return firebase.auth().signOut()
}

export async function loginUser(email: string, password: string) {
 //authenticate with firebase, forward to main page, or offer regsiter/reset/retry
 
 try {
        const res = await firebase.auth().signInWithEmailAndPassword(email, 
        password)
        return res
    } catch (error) {
        console.log(error)
        return false
    }    
}

export async function registerUser(email: string, password: string) {
    //authenticate with firebase, forward to main page, or offer regsiter/reset/retry
       try {
           const res = await firebase.auth().createUserWithEmailAndPassword(email, 
           password)
           console.log(res)
           return true
       } catch (error) {
           console.log(error)
           toast(error.message)
           return false
       }    
   }
   export async function userDelete() {
       //authenticate with firebase, forward to main page, or offer regsiter/reset/retry
       var user = firebase.auth().currentUser;
      if(user) {
       user.delete().then(function() {
         // User deleted.
         return true
       }).catch(function(error) {
         // An error happened.
         return false
       });
    } else {
        return false
    }
      }

export const setUserState = (payload: any) => {
    return { type: 'SET_USER_STATE', payload}
}


/*
const initState ={
    auth: null
}
const authReducer = (state = initState, action:any) => {

}
export default authReducer
*/
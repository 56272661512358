import React, { useState, useEffect, createElement } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, 
IonItem, IonCheckbox , IonLabel, IonBadge, IonButton, IonButtons, IonBackButton, IonCard,IonCardHeader, 
IonCardContent, IonCardTitle, IonCardSubtitle, IonFooter,
 IonNote, IonFab, IonFabButton, IonIcon, IonText, IonGrid, IonRow, IonCol, IonImg, IonLoading, IonAlert, IonItemSliding, IonItemOptions, IonItemOption, IonSearchbar} from '@ionic/react';
import { add, alarm, bicycle, calendar, home, personCircle, refresh,barbell, alarmOutline, checkboxOutline } from 'ionicons/icons';
import axios from 'axios';
import {logout} from '../firebaseConfig'
import './morning.css';
import {  useDispatch,useSelector } from 'react-redux';
import { toast } from '../toast';
import { create } from 'domain';





/*
const sendGetRequest = (uid:string) => {
  setBusy(true)
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=covidList&apiKey=${uid}`;
  return axios({
    url: API_URL,
    method: 'get'
  }).then(response => {
    console.log(response);
    return response.data;
  })
  setBusy(false)
};
*/

{/*
0 first,
1	last,
2 [covid1_id]
3                   ,[covid1_uid]
4                   ,[covid1_tempOk1]
5                  ,[covid1_tempValue]
6                 ,[covid1_contact]
7                ,[covid1_normal]
8               ,[covid1_cough]
9              ,[covid1_tempOk2]
10             ,[covid1_anosmia]
11           ,[covid1_comment]
12         ,[covid1_datetime]
13       ,[covid1_created]
14 approved
15 age (hours)
*/}

{/*
const funItems = (items: any[]) => { 
  const id1: string[] = []; 
    const id2: string[] = []; 
    const id3: string[] = []; 
    const id4: string[] = []; 
    const id5: string[] = []; 
    const id6: string[] = []; 
    const id7: string[] = []; 
    const strid2id: string[] = []; 
    let intCount = 0;
    let i = 0;
  
  //set colours col_1 is red, col_10 is green
  if (items){
    items.forEach(element => {
      // 4 tempOK1
      if (element[4] == 0) {
        id1.push("col_10");
      } else{
        id1.push("col_1");
      }
      // 6 Contact
      if (element[6] == 0) {
        id2.push("col_10");
      } else{
        id2.push("col_1");
      }
      // 7 Feel normal
      if (element[7] == 1) {
        id3.push("col_10");
      } else{
        id3.push("col_1");
      }
     // 8  Cough
      if (element[8] == 0) {
        id4.push("col_10");
      } else{
        id4.push("col_1");
      }
      // 10 Anosmia
      if (element[10] == 0) {
        id5.push("col_10");
      } else{
        id5.push("col_1");      
      }
      // Check all, contact, normal, cough, fever, anosmia
      if (element[6] == 0 && element[7] == 1 && element[8] == 0 && element[9] == 0 && element[10] == 0 ) {
        id5.push("col_10");
        id6.push("OK")
      } else{
        id5.push("col_1"); 
        id6.push("Not OK")    
      }
    })}

    if(items) { return (
      items.map((item,i) => (              
        <IonCard key={item[2]}>                 
       
          <IonCardContent>
         
             {item[0]} {item[1]}<br/>
            <IonBadge id={id1[i]}>
              High Temp: {item[4]}
            </IonBadge>
           
            <IonBadge id={id2[i]} >
              Contact: {item[6]}
            </IonBadge>
         
            <IonBadge id={id3[i]}>
              Feel normal: {item[7]}
            </IonBadge>
         
            <IonBadge id={id4[i]}>
              Cough: {item[8]} 
            </IonBadge>                        
       
            <IonBadge id={id5[i]}>
              Anosmia: { item[9]}
            </IonBadge><br/>
            { item[11]}
          </IonCardContent>                 
        </IonCard>
            
          )))
    }
  */ }

/*const Tab1: React.FC<RouteComponentProps> = (props) => {  DONT USE FIREBASE*/
  const ReceptionCovid1: React.FC<RouteComponentProps> = (props) => {
    const [busy, setBusy] = useState<boolean>(false)
    const [showAlert1, setShowAlert1] = useState(false);
    const uid = useSelector((state:any) => state.user.fb.uid)
    const [items, setItems] = React.useState(['']);   
    const [filteredItems, setFilteredItems] = React.useState(['']);   
   
    const [searchText, setSearchText] = useState('');  
   

    //search filter
    useEffect(() => {
      // filter here
      if (searchText !== "") {
        let newItems:any = items.filter(item => 
        item[0].toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || 
        item[1].toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        ) 
        setFilteredItems(newItems)
      } else {
        setFilteredItems(items)        
        console.log("searchText:"+ searchText)
      }
    }, [searchText])
  
  
    const id1: string[] = []; 
    const id2: string[] = []; 
    const id3: string[] = []; 
    const id4: string[] = []; 
    const id5: string[] = []; 
    const id6: string[] = []; 
    const questCol: string[] = []; 
    const questValue: string[] = []; 
    const tempCol: string[] = []; 
    const tempValue: string[] = []; 
    const approvedCol: string[] = []; 
    const approvedValue: string[] = []; 
    let intCount = 0;
    let i = 0;

//close form, save temperature to API
function funDismissCard (covidId: number, temp: number ) {   
  setBusy(true)
  var formData = new FormData();  
  var approved = 1;
  console.log(covidId)
  console.log(temp)
  formData.set('fid', 'covidTemp');
  formData.set('uid', uid);
  formData.set('covidId', covidId.toString());
  formData.set('temp', temp.toString());
  formData.set('approved', approved.toString());
  const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=covidTemp&apiKey=${uid}`;
  return axios({
    url: API_URL,
    method: 'post',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data' }
  }).then(response => {
    console.log(response);
    setBusy(false)
    funRefeshData()
    return response.data;
  }
  )      
}

//Open form
 function funClickCard(covidId: number,first:string,last:string) {
  console.log("card clicked")
  toast("card clicked: " + covidId.toString())
    const alert = document.createElement('ion-alert');
    alert.header = first + ' ' + last;
    alert.message = 'Enter Temperature';
    alert.buttons=[
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          toast('Confirm Cancel');
        }
      },
      {
        text: 'Submit',
        handler: (alertData ) => {
          toast('Confirm Ok');
          funDismissCard(covidId,alertData.temp)
        }
      }
    ]
    alert.inputs=[
      {
        name: 'temp',
        type: 'text',
        min: 32.5,
        max: 45,
        placeholder: '31'                
      }]
    document.body.appendChild(alert);
    return alert.present();
  }

async function  funRefeshData() {
  await sendGetRequest(uid).then(data => {
    setItems(data.DATA)
    setFilteredItems(data.DATA)
    console.log(data.DATA)
  })
  console.log(filteredItems)
  console.log("refresh done")
}

    async function sendGetRequest(uid:string){
      setBusy(true)
      const  API_URL  =  `https://www.kystrom.com/api/api.cfm?type=covidList&apiKey=${uid}`;
      return axios({
        url: API_URL,
        method: 'get'
      }).then(response => {
        console.log(response.data);
        setBusy(false)
        return response.data;
      }
      )      
    };


    //get list from API
    React.useEffect(() => {
     /* sendGetRequest().then(data => setItems(data.articles));*/
      sendGetRequest(uid).then(data => {
        setItems(data.DATA)
        setFilteredItems(data.DATA)
    });
    }, []);
   
    return (
      <IonPage>
        
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">          
              <IonButton routerLink="/"  shape="round" fill="outline" color="light">
              <IonIcon icon={home}/>
                &nbsp;Home
              </IonButton>
           </IonButtons>
           <IonTitle color="light" size="large" class="ion-text-center">Reception</IonTitle>
            <IonButtons slot="end">                  
              <IonButton shape="round" fill="outline"  color="light" onClick={logout} >
                <IonIcon icon={personCircle}/> 
                &nbsp;Logout           
              </IonButton>                        
            </IonButtons>           
          </IonToolbar>
        </IonHeader>
        <IonLoading message="" isOpen={busy}/>
        <IonContent>
      
       

  {
  filteredItems.map((element:any,i) =>  {
    //items.map((element:any,i) =>  {
     //set colours col_1 is red, col_10 is green
   
   // 4 tempOK1
      if (element[4] == 0) {
        id1.push("col_10");
      } else{
        id1.push("col_1");
      }
      // 6 Contact
      if (element[6] == 0) {
        id2.push("col_10");
      } else{
        id2.push("col_1");
      }
      // 7 Feel normal
      if (element[7] == 1) {
        id3.push("col_10");
      } else{
        id3.push("col_1");
      }
     // 8  Cough
      if (element[8] == 0) {
        id4.push("col_10");
      } else{
        id4.push("col_1");
      }
      // 9 Anosmia
      if (element[9] == 0) {
        id5.push("col_10");
      } else{
        id5.push("col_1");      
      }
     
      // Check all, contact, normal, cough, fever, anosmia
      if (element[6] == 0 && element[7] == 1 && element[8] == 0 && element[9] == 0 && element[10] == 0 ) {
        questCol.push("col_10");
        questValue.push("OK")
      } else{
        questCol.push("col_1"); 
        questValue.push("Not OK")    
      }
      // Check temperature value
      if (element[5] < 34 ) { //Too low / Not checked
        tempCol.push("col_1");
        tempValue.push("Not Checked (< 34 C)")
      } 
      else if (element[5] < 37.8 ) { //OK
        tempCol.push("col_10");
        tempValue.push(element[5])
      }
      else{ //Too high
        tempCol.push("col_1"); 
        tempValue.push(element[5])    
      }
       // Check approved
       if (element[6] == 0 && element[7] == 1 && element[8] == 0 && element[9] == 0 && element[10] == 0 && element[5] < 37.8 && element[5] > 34 ) {
        approvedCol.push("col_10");
        approvedValue.push("Approved")
      } else{
        approvedCol.push("col_1"); 
        approvedValue.push("Not approved")    
      }
    })
    }

        <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>

        <IonList> 

            
     
       
        { filteredItems.map((item,i) => (  
      
       <IonItemSliding>
       <IonItem key={item[2]} button onClick={() => {funClickCard(parseInt(item[2]),item[0],item[1])}}>
       <IonLabel class="ion-text-wrap">
           <h1>
             {item[0]} {item[1]} 
             </h1>
             <IonBadge id={approvedCol[i]}>
              {approvedValue[i]}
           </IonBadge>
           <br/>
           Questionnaire: {questValue[i]}
          {//<IonBadge id={questCol[i]}>
          }
             <br/>
         
           Temp check: {tempValue[i]}
          
          
          
          {/*
           <IonBadge id={id2[i]} >
             Contact: {item[6]}
           </IonBadge>
        
           <IonBadge id={id3[i]}>
             Feel normal: {item[7]}
           </IonBadge>
         
           <IonBadge id={id4[i]}>
             Cough: {item[8]} 
           </IonBadge>                        
      
           <IonBadge id={id5[i]}>
             Anosmia: { item[9]}
          </IonBadge><br/>*/}
           <p>
           {item[15]} hours ago<br/>
           { item[11]}
           </p>
           </IonLabel>
         </IonItem>
         <IonItemOptions side="end">
           <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
        </IonItemOptions>
    </IonItemSliding> 
        ))}

  
  </IonList>


        <IonFab vertical="bottom" horizontal="end" slot="fixed">            
            {//</IonFab>/<IonFabButton onClick={() => sendGetRequest(uid).then(data => setItems(data.DATA))}  color="secondary">    
             }
            <IonFabButton onClick={() => funRefeshData()}  color="secondary">
              <IonIcon icon={refresh} />
            </IonFabButton>
          </IonFab>

        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle>Advert</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

export default ReceptionCovid1;
